import React,{ useEffect, useState } from "react"
import Tile from './Tile'

import { BrowserRouter as Router, Route, Switch, Link } from 'react-router-dom';

const random = require('canvas-sketch-util/random');

let colours = ["#95ad55","#f3db3d","#de4652","#cd5e29","#35294d","#6582ae","#6582ae"]

const MainIndex = ({color,imageUrl}) => {
  
        const [tiles, setTiles] = useState([])

        return ( 
            <div className="Gallery">
                <div className="gallery-header">
                    <a href="/"><img className="gallery-logo" src="./rondel.svg"/></a>
                    <h1>Ladies Night Gallery</h1>
                    <h3>By <a target="_Blank" href="https://www.burtonroundtable.co.uk">Burton Round Table</a></h3>

                    <h2 className="select-year">Please select the year you want to view</h2>

                    <div className="gallery-years">
                      <div className="gallery-year"><Link to="/Gallery/2025">2025</Link></div>
                      <div className="gallery-year"><Link to="/Gallery/2023"  >2023</Link></div>
                      <div className="gallery-year"><Link to="/Gallery/tablemoments"  >Round Table Highligts</Link></div>
                      <div className="gallery-year"><Link to="/Gallery/marshallmoments"  >Marshall Moments</Link></div>
                    </div>
                    

                </div>
   
            </div>
        )


}

export default MainIndex