import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX } from "@fortawesome/free-solid-svg-icons";
import styles from "./GalleryPlayer.module.css";
const random = require("canvas-sketch-util/random");

const GalleryPlayer = ({ tiles, closeModel }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  // nextIndex is set when we’re about to transition
  const [nextIndex, setNextIndex] = useState(null);
  // fadeActive triggers the CSS fade-in on the next image
  const [fadeActive, setFadeActive] = useState(false);

  const shuffledTiles = random.shuffle(tiles);


  const loadNextImage = () => {

    // Pick a different image index
    let next = currentIndex;
    next = next + 1;
    if (next >= shuffledTiles.length) {
      next = 0;
    }
    setCurrentIndex(next);
    console.log('loading next image' + next);
    // if (tiles.length > 1) {
    //   do {
    //     next = Math.floor(Math.random() * tiles.length);
    //   } while (next === currentIndex);
    // }
    //setNextIndex(next);
  }

  // Timer to start a new transition at a random interval
  useEffect(() => {
    // Only set a new transition if one isn’t already underway
    if (nextIndex === null) {
      const randomInterval = 9000;

      const timer = setInterval(() => {
        loadNextImage();
      }, randomInterval);

      return () => clearTimeout(timer);
    }
  }, [shuffledTiles]);

  return (
    <div className={styles.GalleryPlayer}>
      <div className={styles.galleryImageWrapper}>
        <div className={styles.closeButton} onClick={closeModel}>
          <FontAwesomeIcon icon={faX} />
        </div>
        {/* Current image remains visible in the background */}
        <img
          className={styles.galleryImage}
          src={shuffledTiles[currentIndex].src}
          alt="Gallery current"
        />
      </div>
    </div>
  );
};

export default GalleryPlayer;
