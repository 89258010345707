import React,{ useEffect, useState } from "react"
import Tile from './Tile'
import { useParams } from 'react-router-dom'; // Import useParams
import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCoff, faX, faDownload, faShare } from '@fortawesome/free-solid-svg-icons'
import GalleryPlayer from './GalleryPlayer'

const random = require('canvas-sketch-util/random');

let colours = ["#95ad55","#f3db3d","#de4652","#cd5e29","#35294d","#6582ae","#6582ae"]

const Gallery = ({color,imageUrl}) => {
  
    const [modalIsOpen, setIsOpen] = React.useState(false);
  
      function openModal() {
        setIsOpen(true);
      }
    
      function afterOpenModal() {
  
      }
    
      function closeModal() {
          setIsOpen(false);
      }

      const customStyles = {
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          maxHeight:'80vh',
          maxWidth:'86vw',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          backgroundColor:color,
          border:'0px'
        },
      };

        const { galleryId } = useParams(); // Use useParams to get galleryId from the URL
        
        const [pageTitle,setPageTitle] = useState("LADIES NIGHT");

        const [tiles, setTiles] = useState([])
        const url = 'https://photogalleryapi.azurewebsites.net/API/GetGallery?id='+galleryId;

        useEffect(()=> {
            const response = fetch(url, {
                method: 'GET',
                mode:'cors',
                headers: {
                    'x-functions-key':'TsH7zTzJAzvPANxxTfVGHhZzTst9btF2PgMwjuZQMUwPAzFu-ynPUw==',
                  'Accept': 'application/json'
                  
                },
              })
              .then((res) => res.json())
              .then((data) => {
                let tiles = [];
                var gallery = JSON.parse(data);
                setPageTitle(gallery.Title);
                let lastColour = "";
                let nextColor = "#95ad55"; 
                
                for(let t=0; t< gallery.Photos.length; t++){
                  nextColor = random.pick(colours);
              
                  while(nextColor == lastColour){
                    nextColor =  random.pick(colours);
                  }
                  lastColour = nextColor;
              
                  tiles.push({name:'tile-'+t, title:"hi", color:nextColor,height:random.range(200,350)+"px", src:gallery.Photos[t].PhotoURL,thumbnail:gallery.Photos[t].ThumbnailURL,filename:gallery.Photos[t].FileName,width:"32%"});
                }
                setTiles(tiles);
              })
              .catch(error => console.log('Error while fetching:', error))
    
        },[url]);

        function viewFullScreen() {
          openModal();
        }

        if(tiles.length == 0){
          return 'Loading...';
        }


        return ( 
            <div className="Gallery">
                <div className="gallery-header">
                    <a href="/"><img className="gallery-logo" src="../rondel.svg"/></a>
                    <h1>{pageTitle}</h1>
                    <h3>By <a target="_Blank" href="https://www.burtonroundtable.co.uk">Burton Round Table</a></h3>
                    {tiles.length == 0 &&
                        <h2>No images have been published yet, please come back soon!</h2>
                    } 
                    {tiles.length > 0 &&
                      <div>
                        <h2>Please scroll to find your photo and click to download</h2>
                        <h3 onClick={viewFullScreen}>Or click here to view full screen</h3>
                      </div>
                    } 

                </div>

                {tiles.length > 0 &&
                    tiles.map(({color,title,height,width,src,name,thumbnail,filename}) => <Tile key={name} color={color} imageUrl={src} thumbnailUrl={thumbnail} filename={filename}/> )
                }

                {modalIsOpen && <GalleryPlayer tiles={tiles} closeModel={closeModal}/>}
   
            </div>
        )


}

export default Gallery