import logo from './logo.svg';
import './App.css';
import MainIndex from './MainIndex';
import Modal from 'react-modal';

import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import Gallery from './Gallery';

function App() {

  
  // Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
  Modal.setAppElement('#root');

  return (

      <div className="App">
        <header className="App-header">
        <Router>
          <Routes>
            <Route path="/" element={<MainIndex />} />
            <Route path="/gallery/:galleryId" element={<Gallery />} />
          </Routes>
        </Router>
        </header>
      </div>
  );
}

export default App;
