import React, { useState, useEffect } from "react";
import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCoff, faX, faDownload, faShare,faSpinner } from '@fortawesome/free-solid-svg-icons'


const random = require('canvas-sketch-util/random');

const Tile = ({color,imageUrl,thumbnailUrl,filename}) => {


    const customStyles = {
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          maxHeight:'80vh',
          maxWidth:'86vw',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          backgroundColor:color,
          border:'0px'
        },
      };

    const [modalIsOpen, setIsOpen] = useState(false);

    const [blobUrl, setBlobUrl] = useState(null);

    const [loading, setLoading] = useState(false);

    function openModal() {
      setIsOpen(true);
      setLoading(true);

      // Start preloading the image
      fetch(imageUrl)
          .then((response) => response.blob())
          .then((blob) => {
              const objectUrl = URL.createObjectURL(blob);
              setBlobUrl(objectUrl);
          })
          .finally(() => setLoading(false));
  }
  
    function afterOpenModal() {

    }
  
    function closeModal() {
      setIsOpen(false);

      // Clean up Blob URL when modal is closed
      if (blobUrl) {
          URL.revokeObjectURL(blobUrl);
          setBlobUrl(null);
      }
  }

  function download() {
    setLoading(true);

    if (blobUrl) {
        triggerDownload(blobUrl);
        setLoading(false);
    } else {
        // If blob isn't ready, fetch and download it
        fetch(imageUrl)
            .then((response) => response.blob())
            .then((blob) => {
                const objectUrl = URL.createObjectURL(blob);
                setBlobUrl(objectUrl);
                triggerDownload(objectUrl);
            })
            .finally(() => setLoading(false));
    }
  }

  function triggerDownload(url) {
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

    function share(){
        window.open('http://www.facebook.com/sharer.php?u='+encodeURIComponent(imageUrl)+'&t='+encodeURIComponent(filename),'sharer','toolbar=0,status=0');

    }


return(
    <div className="Tile" style={{backgroundColor:color}} >
        <div className="image-wrapper" onClick={openModal}>
                <img className="image-main" src={thumbnailUrl} />
        </div>
        <Modal
            isOpen={modalIsOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={customStyles}
        >
            <div className="close-model" onClick={closeModal}><FontAwesomeIcon icon={faX}/></div>
            <div className="model-body" >
                <img className="large-image" src={thumbnailUrl} />
                <h2 className="info-message">Click the download button to get the full quality version</h2>
            </div>
            <div className="button-bar">
                    <button onClick={download} disabled={loading}>
                        {loading ? <FontAwesomeIcon icon={faSpinner} spin size="xl" /> : <FontAwesomeIcon icon={faDownload} size="xl" />}
                    </button>
            </div>
        </Modal>
    </div>

)}

export default Tile